import type { RouteState } from "@/__main__/router.mjs";
import {
  GameMode,
  MATCHLIST_OFFSET,
  SearchParamsEnum,
} from "@/game-eft/constants/constants.mjs";
import fetchMatches from "@/game-eft/fetches/matches.mjs";
import fetchMatchlist from "@/game-eft/fetches/matchlist.mjs";
import fetchPlayerFactionStats from "@/game-eft/fetches/player-faction-stats.mjs";
import fetchPlayerMapStats from "@/game-eft/fetches/player-map-stats.mjs";
import fetchPlayerWeaponStats from "@/game-eft/fetches/player-weapon-stats.mjs";
import fetchProfile from "@/game-eft/fetches/profile.mjs";
import { fetchSeasons } from "@/game-eft/fetches/seasons.mjs";
import { devError } from "@/util/dev.mjs";

export default async function fetchData(
  [profileId]: Array<string>,
  searchParams: URLSearchParams,
  state: RouteState,
) {
  try {
    const gameMode =
      GameMode[searchParams.get(SearchParamsEnum.GameMode)] ?? GameMode.DEFAULT;
    // Fetch profile
    await Promise.all([fetchSeasons(), fetchProfile(profileId)]);
    const offset = (state.transient?.offset as number) || MATCHLIST_OFFSET;
    await Promise.all([
      fetchMatchlist({
        offset,
        profileId,
      }),
      fetchPlayerWeaponStats({
        profileId,
        gameMode,
      }),
      fetchPlayerFactionStats({
        profileId,
      }),
      fetchPlayerMapStats({
        profileId,
      }),
    ]);
  } catch (e) {
    devError("Failed to fetch EFT profile page data", e);
  }
  // Transient matches
  const gameIds = Object.entries(state.transient?.visibleMatches ?? {})
    .filter(([_, isVisible]) => isVisible)
    .map(([gameId]) => gameId);
  await fetchMatches(gameIds);
}
