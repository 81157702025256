import getData from "@/__main__/get-data.mjs";
import type { GameMode } from "@/game-eft/constants/constants.mjs";
import type { PlayerWeaponStats } from "@/game-eft/models/graphql.mjs";
import { PlayerWeaponStatsValidate } from "@/game-eft/models/graphql.mjs";
import * as API from "@/game-eft/utils/api.mjs";

export default async function fetchPlayerWeaponStat({
  profileId,
  gameMode,
}: {
  profileId: string;
  gameMode: GameMode.ARENA | GameMode.DEFAULT;
}): Promise<PlayerWeaponStats> {
  const playerWeaponStats = await getData(
    API.getPlayerWeaponStats({
      accountId: profileId,
      gameMode,
    }),
    PlayerWeaponStatsValidate,
    ["eft", "playerWeaponStat", profileId, gameMode],
    {
      shouldFetchIfPathExists: true,
      skipSafetyCheck: true,
      mergeFn(current, next) {
        return next;
      },
      networkBackOffTime: 0,
    },
  );
  return playerWeaponStats;
}
