import getData from "@/__main__/get-data.mjs";
import type { PlayerFactionStats } from "@/game-eft/models/graphql.mjs";
import { PlayerFactionStatsValidate } from "@/game-eft/models/graphql.mjs";
import * as API from "@/game-eft/utils/api.mjs";
import { devError } from "@/util/dev.mjs";

export default async function fetchPlayerFactionStats({
  profileId,
}: {
  profileId: string;
}): Promise<PlayerFactionStats> {
  try {
    const playerFactionStats = await getData(
      API.getPlayerFactionStats({
        accountId: profileId,
      }),
      PlayerFactionStatsValidate,
      ["eft", "playerFactionStat", profileId],
      {
        shouldFetchIfPathExists: true,
        skipSafetyCheck: true,
        mergeFn(current, next) {
          return next;
        },
        networkBackOffTime: 0,
      },
    );
    return playerFactionStats;
  } catch (e) {
    devError(
      "EFT failed to fetch player faction stats because the season id is invalid",
      e,
    );
  }
}
