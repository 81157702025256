import getData from "@/__main__/get-data.mjs";
import type { PlayerMapStats } from "@/game-eft/models/graphql.mjs";
import { PlayerMapStatsValidate } from "@/game-eft/models/graphql.mjs";
import * as API from "@/game-eft/utils/api.mjs";

export default async function fetchPlayerMapStats({
  profileId,
}: {
  profileId: string;
}): Promise<PlayerMapStats> {
  const playerMapStats = await getData(
    API.getPlayerMapStats({
      accountId: profileId,
    }),
    PlayerMapStatsValidate,
    ["eft", "playerMapStat", profileId],
    {
      shouldFetchIfPathExists: true,
      skipSafetyCheck: true,
      mergeFn(current, next) {
        return next;
      },
      networkBackOffTime: 0,
    },
  );
  return playerMapStats;
}
